import { MatterTerminology } from '../MatterTerminology';
import { ColumnProperty, applyCellRendererToColumn } from '../matterSummary/MatterSummaryColumns';
import { DataType } from '../components/DataDisplay/ValueFormatter';
import { ColDef } from '@ag-grid-enterprise/all-modules';

function getFilterForDataType(dataType: number): string | void {
    switch (dataType) {
        case DataType.Date:
            return 'date';
        case DataType.Default:
        case DataType.Currency:
            return 'number';
        case DataType.Boolean:
            return 'boolean';
        default:
            return;
    }
  }
  
export function ColumnConfig(isAdministrator: boolean): Array<ColDef> {
    var result = new Array<ColDef>();
    MatterSummaryConfigColumns.forEach(column => {
        var filter = getFilterForDataType(column.format);
        var colDef = {
            field: column.field ? column.field : '',
            headerName: column.headerName ? column.headerName : '',
            dataType: column.format,
            width: column.width ? column.width : 100,
            format: column.format,
            filter: filter ? filter : '',
            hide: (column.isHideCustom ? !isAdministrator : column.hide),
            show: !column.hide,
            enableRowGroup: false,
            sort: column.sort ? column.sort : '',
            // tooltipField: column.format === DataType.String ? column.field : undefined
        };
        applyCellRendererToColumn(colDef, column.format);
        result.push(colDef);
    });
    return result; 
  
  }
  
export const MatterSummaryConfigColumns: Array<ColumnProperty> = [
    {
        field: 'name',
        headerName: MatterTerminology.MatterSummaryConfigTemplateName,
        format: DataType.String,
        enableRowGroup: false,
        hide: false,
        pinned: 'left',
        width: 240
    },
    {
        field: 'configId',
        headerName: MatterTerminology.MatterSummaryConfigId,
        format: DataType.String,
        enableRowGroup: false,
        hide: true
    },
    {
        field: 'isTenantDefault',
        headerName: MatterTerminology.MatterSummaryConfigIsTenantLevel,
        format: DataType.Boolean,
        enableRowGroup: false,
        hide: false,
        isHideCustom: true,
        width: 150
    }
  ];