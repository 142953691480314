import gql from 'graphql-tag';
import { client } from '..';
import { FetchPolicy } from 'apollo-client';

export interface UserOptionsData {
    loading?: boolean;
    networkStatus?: number;
    options: UserOptionsResults;
}

export interface UserOptionsResults {
    phoneSettings: TenantUserOptions;
    localPhoneSettings: LocalUserOptions;    
}

export class TenantUserOptions {
    unitsPerHour: string;
    warnIfNotToday: string;
    timeCaptureMode: string;
    tenantTimeCaptureMode: string;
    useStartEndTime: string;
    showStartEndTime: string;
    copyActivityAsNote: string;
    expectedHoursPerDay: string;
    standardHoursPerDay: string;
    showWarningOnExceedingHours: string;
    shouldShowFavouriteActivitiesFirst: string;
    phoneCallDefaultActivity: string;
    emailDefaultActivity: string;
    defaultActivity: string;
    defaultDurationUnits: string;
    defaultDurationMinutes: string;
    timeSelectionMinuteInterval: string;
    timeslipContactInfo: string;
    submissionType: string;
    welcomeText: string;
    staffContactFilter: string;
    defaultToDraft: string;
}

export class TypedTenantUserOptions {
    unitsPerHour: number;
    defaultMatter: string;
    warnIfNotToday: boolean;
    timeCaptureMode: string;
    tenantTimeCaptureMode: string;
    useStartEndTime: boolean;
    showStartEndTime: boolean;
    copyActivityAsNote: boolean;
    expectedHoursPerDay: number;
    standardHoursPerDay: number;
    showWarningOnExceedingHours: boolean;
    shouldShowFavouriteActivitiesFirst: boolean;
    phoneCallDefaultActivity: string;
    emailDefaultActivity: string;
    defaultActivity: string;
    defaultDurationUnits: number;
    defaultDurationMinutes: number;
    timeSelectionMinuteInterval: number;
    timeslipContactInfo: string;
    submissionType: string;
    staffContactFilter: string;
    defaultToDraft: boolean;
    constructor() {
        this.unitsPerHour = 10;
        this.warnIfNotToday = true;
        this.tenantTimeCaptureMode = 'Both';
        this.timeCaptureMode = 'Units';
        this.useStartEndTime = true;
        this.showStartEndTime = true;
        this.copyActivityAsNote = true;
        this.expectedHoursPerDay = 8;
        this.standardHoursPerDay = 8;
        this.showWarningOnExceedingHours = false;
        this.shouldShowFavouriteActivitiesFirst = false;
        this.defaultToDraft = false;
        this.phoneCallDefaultActivity = '';
        this.emailDefaultActivity = '';
        this.defaultActivity = '';
        this.defaultDurationUnits = 5;
        this.defaultDurationMinutes = 30;
        this.timeSelectionMinuteInterval = 1;
        this.timeslipContactInfo = '';
        this.submissionType = 'Automatic';
        this.staffContactFilter = '';
    }
}

export interface LocalUserOptions {
    settingsJson: string;
}

export function fetchUserSettings(refreshData: boolean,
                                  onSuccess: (data: UserOptionsData) => void,
                                                    // tslint:disable-next-line:no-any
                                  onError: (reason: any) => void)
                                            : void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client.query({
        query: UserSettings,
        variables: {
        },
        fetchPolicy: fetchPolicy
    })
    // tslint:disable-next-line:no-any
    .then((results: { data: any; }) => {
        onSuccess(results.data);
    })
    // tslint:disable-next-line:no-any
    .catch((reason: any ) => { 
        onError(reason); 
    });
}

export function fetchTypedUserSettings(refreshData: boolean,
                                       onSuccess: (data: TypedTenantUserOptions) => void,
                                       // tslint:disable-next-line:no-any
                                       onError: (reason: any) => void): void {

    fetchUserSettings(refreshData, (returnedData: UserOptionsData) => {
        if (returnedData.options.phoneSettings) {
            var entries = Object.entries(returnedData.options.phoneSettings);
            var options = new TypedTenantUserOptions();

            for (const [name, value] of entries) {
                var type = typeof options[name];
                if (value) {
                    switch (type) {
                        case ('number') :
                            // tslint:disable-next-line:radix
                            options[name] = parseInt(value);
                            break;
                        case ('boolean') :
                            options[name] = value === 'true' || value === 'True';
                            break;
                        default :
                            options[name] = value;
                            break;
    
                    }
                }
              
            }
            onSuccess(options);
        }            
    },                (reason) => { onError(reason); }  );                                   
                                    
}

const UserSettings = gql`query userSettings {
    options {
      phoneSettings {
        unitsPerHour
        warnIfNotToday
        timeCaptureMode
        tenantTimeCaptureMode
        useStartEndTime
        showStartEndTime
        copyActivityAsNote
        expectedHoursPerDay
        standardHoursPerDay
        showWarningOnExceedingHours
        shouldShowFavouriteActivitiesFirst
        phoneCallDefaultActivity
        emailDefaultActivity
        defaultActivity
        defaultDurationUnits
        defaultDurationMinutes
        timeSelectionMinuteInterval
        timeslipContactInfo
        submissionType
        staffContactFilter
      }
      localPhoneSettings(appType: "Web") {
        settingsJson
      }
    }
  }
  `;
