import * as React from 'react';
import glamorous from 'glamorous';
import { MatterTerminology } from '../MatterTerminology';
import { Form, Checkbox, CheckboxProps, ButtonProps } from 'semantic-ui-react';
import { RvButtonFabric, RvButtonType, RvButton } from '../components/Button';
import { RvLabel, LabelStyle } from '../components/Label/Label';
import { SummaryViewConfigObj } from './SummaryViewConfigurations';
import { ColumnConfig } from './SummaryViewConfigColumns';
import _ from 'lodash';
import { SaveSummaryViewConfiguration } from './SummaryViewConfigRepository';
import { showNotification } from '../App';
import { RvModal } from '../components/Modal';
import { RvInput } from '../components/Input';
import DialogBox from '../Dashboard/Component/DialogBox';
import { ColDef, GridReadyEvent, RowSelectedEvent, AllModules, GridOptions } from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from '@ag-grid-community/react/lib/agGridReact';

// tslint:disable-next-line: no-any
const DataGridSection = glamorous.div<{ theme?: any }>((props) => ({
  height: '100%',
  width: '100%',
  [`& .${`ag-root-wrapper-body`}`]: {
    // display: 'flex',
    // justifyContent: 'center'
  },
  [`& .${`grid-container`}`]: {
    // height: window.innerHeight - 440,
    [`.${`print`}`]: {
      height: 'auto !important'
    },
    [`& .${`ag-tool-panel`}`]: {
      [`& .${`ag-side-buttons`}`]: {
        display: 'none'
      }
    }
  },
  [`& .${`hideGroupPanel`}`]: {
    [`& .${`ag-column-drop-row-group`}`]: {
      display: 'none'
    }
  },
  [`& .${`showGroupPanel`}`]: {
    [`& .${`ag-column-drop-row-group`}`]: {
      display: 'block'
    }
  },
  [`& .${`ag-bl-normal`}`]: {
    textAlign: 'left',
    width: '100%',
  },
  [`& .${`ag-body-viewport-wrapper`}`]: {
    color: props.theme.BrandColors.TextSecondary,
    fontFamily: props.theme.Font.Main,
    fontSize: '14px'
  },
  [`& .${`ag-header-viewport`}`]: {
    backgroundColor: props.theme.NeutralColors.LightBlue2
  },
  [`& .${`ag-column-drop`}`]: {

  },
  [`& .${`ag-column-drop-row-group`}`]: {
    borderBottom: 0,
    backgroundColor: props.theme.NeutralColors.LightGrey1,
    color: props.theme.BrandColors.TemplatePrimary,
  },
  [`& .${`ag-header-row`}`]: {
    borderBottom: 'none !important',
    color: props.theme.BrandColors.TextPrimary,
  },
  [`& .${`ag-header-cell`}`]: {
    lineHeight: '40px !important',
    padding: '0px !important'
  },
  [`& .${`ag-icon-menu`}`]: {
    height: '40px !important',
  },
  [`& .${`ag-header-cell-label`}`]: {
    fontSize: '14px',
    fontWeight: props.theme.FontWeight.Normal,
    padding: '0 15px',
    fontFamily: props.theme.Font.Main
  },
  [`& .${`ag-column-drop-icon`}`]: {
    color: props.theme.BrandColors.TemplatePrimary,
  },
  // [`& .${`ag-body`}`]: {
  //     top: '40px !important'
  // },
  [`& .${`ag-row`}`]: {
    borderBottom: 'none !important'
  },
  [`& .${`ag-cell`}`]: {
    padding: '0 15px !important'
  },
  [`& .${`ag-header-cell-resize`}`]: {
    '::after': {
      marginTop: '8px !important',
    }
  },
  [`& .${`ag-tab`}`]: {
    backgroundColor: props.theme.NeutralColors.LightGrey1
  },
  [`& .${`ag-tab-selected`}`]: {
    borderBottom: '2px solid ' + props.theme.BrandColors.TemplatePrimary
  },
  [`& .${`ag-row-odd`}`]: {
    backgroundColor: props.theme.NeutralColors.LightGrey1
  },
  [`& .${`ag-row-hover`}`]: {
    backgroundColor: props.theme.NeutralColors.BackgroundWhite,
    color: props.theme.BrandColors.TemplatePrimary,
    //     border: '1px solid' + props.theme.BrandColors.TemplatePrimary,
    boxShadow: '0 0 4px 0 ' + props.theme.NeutralColors.BackgroundGrey2
  },
  [`& .${`ag-overlay-loading-center`}`]: {
    padding: 10,
    backgroundColor: props.theme.NeutralColors.BackgroundWhite,
    color: props.theme.BrandColors.TemplatePrimary,
    border: '1px solid ' + props.theme.BrandColors.TemplatePrimary,
    borderRadius: '.28571429rem !important',
  },
  [`& .${`ag-full-width-row`}`]: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [`& .${`ag-loading-text`}`]: {
      color: props.theme.BrandColors.TemplatePrimary,
      margin: '0px !important'
    },
    [`& .${`ag-loading-icon`}`]: {
      color: props.theme.BrandColors.TemplatePrimary,
    }
  },
  [`& .${`ag-react-container`}`]: {
    // display: 'inline-block',
    // width: '100%',
    // height: '100%',
    // overflow: 'hidden'
  },
  // [`& .${`ag-full-width-row`}`]: {
  //     display: 'none'
  // }        
}));

const StyledView = glamorous.div({});

const StyledHeader = glamorous.div<{ theme?: object }>(props => ({}));

const StyledHeading = glamorous.div({
  width: '100%',
  marginTop: '30px',
  display: 'flex',
  alignItems: 'center',
  marginBottom: '20px',
  [`& .${`sidebar-headline-icon`}`]: {
    marginRight: '30px'
  }
});

const FormStyle = glamorous.div({
  width: 'Auto',
  textAlign: 'left'
});

// tslint:disable-next-line:no-any
const Actions = glamorous.div<{ theme?: any }>(props => ({
  paddingTop: '10px',
  [`& .${`ms-Button`}`]: {
    width: '100%'
  },
  [`> .${`custom-button`}`]: {
    paddingBottom: '10px',
    [`> .${`ms-Button`}`]: {
      paddingTop: '20px',
      paddingBottom: '20px'
    }
  },
  [`& .${`button-save`}`]: {
    [`> .${`ms-Button`}`]: {
      borderRadius: '8px',
      // backgroundColor: props.theme.TemplateColor.Primary,
      // ':active': {
      //     backgroundColor: props.theme.CommonColor.LightBlue4,
      // }
    },
    // [`& .${`ms-Button-flexContainer`}`]: {
    //   color: '#ffffff'
    // }
  },
  [`& .${`button-delete`}`]: {

    [`> .${`is-disabled`}`]: {
      backgroundColor: props.theme.CommonColor.LightGrey4 + '!important',
    },
    [`> .${`ms-Button`}`]: {
      borderRadius: '8px',
      backgroundColor: 'rgb(202, 16, 16)',
      ':active': {
        backgroundColor: 'rgb(219, 41, 41) !important'
      }
    },
    [`& .${`ms-Button-flexContainer`}`]: {
      // color: '#ffffff'
    }
  }
  // zIndex: 0,
  // top: '175px',
  // margin: '0 50px'
}));

const ButtonContainerSaveAs = glamorous.div({
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'flex-end',
});

const InputcontainerSaveAs = glamorous.div({
  [`& .${`input-templateName`}`]: {
    margin: 0
  }
});

const ConfigContainer = glamorous.div({
  [`& .${`matterconfig-margin-top`}`]: {
    marginTop: '15px !important',
    marginBottom: '15px !important'
  }
});

export interface SummaryViewConfigState {
  show: boolean;
  isAdministrator: boolean;
  updateShow: boolean;
  deleteShow: boolean;
  saveAsShow: boolean;
  renameShow: boolean;

  configNameSaveAs: string;
  configName: string;
  configId: number;

  isPrivate: boolean;
  isTenantDefault: boolean;
  isDefault: boolean;
  isHideIsPrivate: boolean;
  isDiableSave: boolean;
  isHideIsDefault: boolean;
  isHideIsTenantDefault: boolean;
}

export interface SummaryViewConfigProps {
  views?: Array<SummaryViewConfigObj>;
  configType: number; // 1 = MatterSummary, 2 = LeadSummary
  isAdministrator: boolean;
  onClose?: (show: boolean) => void;
  onRowSelected?: (configId: number) => void;
  onViewUpdated?: () => void;
  viewConfig?: string; // Current config to be saved
  viewConfigId: number;
  isNewMatterSummary: boolean;
}

// tslint:disable-next-line:max-line-length
export class SummaryViewConfig extends React.Component<SummaryViewConfigProps, SummaryViewConfigState> {
  private viewLayout?: Array<ColDef>;
  private gridOptions: GridOptions;
  
  constructor(props: SummaryViewConfigProps, state: SummaryViewConfigState) {
    super(props, state);

    this.onClose = this.onClose.bind(this);
    this.getForm = this.getForm.bind(this);
    this.getActionButtons = this.getActionButtons.bind(this);
    this.getSaveAsDialog = this.getSaveAsDialog.bind(this);
    this.getRenameDialog = this.getRenameDialog.bind(this);
    this.handleControlLogic = this.handleControlLogic.bind(this);

    this.getViewLayout = this.getViewLayout.bind(this);
    this.onRowSelected = this.onRowSelected.bind(this);

    this.handleTextChange = this.handleTextChange.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.handleUpdateAgreed = this.handleUpdateAgreed.bind(this);
    this.handleDeleteAgreed = this.handleDeleteAgreed.bind(this);

    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleSaveAs = this.handleSaveAs.bind(this);
    this.handleRename = this.handleRename.bind(this);
    this.handleDelete = this.handleDelete.bind(this);

    this.onClicCloseDialog = this.onClicCloseDialog.bind(this);
    this.onClickSaveAs = this.onClickSaveAs.bind(this);
    this.onClickRename = this.onClickRename.bind(this);

    this.state = {
      show: false,
      isAdministrator: this.props.isAdministrator,
      updateShow: false,
      deleteShow: false,
      saveAsShow: false,
      renameShow: false,
      configNameSaveAs: '',
      configName: '',
      isPrivate: true,
      isTenantDefault: false,
      isDefault: false,
      configId: this.props.viewConfigId,

      isHideIsPrivate: this.props.isAdministrator ? false : true,
      isDiableSave: true,
      isHideIsDefault: false,
      isHideIsTenantDefault: false
    };
  }

  public componentDidMount() {
    this.handleControlLogic('isPrivate', true, this.state);

    if (this.props.viewConfigId) {
      // tslint:disable-next-line: no-console
      // console.log('componentDidMount', this.props.viewConfigId);

      this.populateData();
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: SummaryViewConfigProps, nextState: SummaryViewConfigState): void {
    if (this.props.viewConfigId !== nextProps.viewConfigId
      || this.props.isAdministrator !== nextProps.isAdministrator) {
      this.setState({
        configId: nextProps.viewConfigId,
        isAdministrator: nextProps.isAdministrator,
        isHideIsPrivate: nextProps.isAdministrator ? false : true,
      });
    }
  }

  public componentDidUpdate(prevProps: SummaryViewConfigProps, prevState: SummaryViewConfigState) {
    if (this.state.configId !== prevState.configId) {
      this.populateData();
    }
  }

  public render() {

    this.viewLayout = this.getViewLayout();    
    this.gridOptions = {
      defaultColDef: {
          resizable: true,
          sortable: true
      },
      rowClass: 'custom-ag-selected'
  };

    return (
      <StyledView>
        <StyledHeader>
          <div>
            <StyledHeading>
              <RvButtonFabric
                rvKey="close"
                rvbuttonType={RvButtonType.icon}
                onClick={this.onClose}
                rvIcon={'close-panel-svg'}
                rvClassName="sidebar-headline-icon"
              />
              <RvLabel label="Views" style={LabelStyle.Heading2} />
            </StyledHeading>
          </div>
        </StyledHeader>
        <FormStyle>
          <Form>
            <DialogBox
              title="Confirmation Message"
              content="Are you sure you want to update?"
              show={this.state.updateShow}
              isAgree={this.handleUpdateAgreed}
              disAgreeLabel={'No'}
              agreeLabel={'Yes'}
            />
            <DialogBox
              title="Confirmation Message"
              content="Are you sure you want to delete?"
              show={this.state.deleteShow}
              isAgree={this.handleDeleteAgreed}
              disAgreeLabel={'No'}
              agreeLabel={'Yes'}
            />
            <DataGridSection
              id="SummaryGrid"
              className="ag-theme-material"
            >
              <div style={{ height: '200px' }}>
                <AgGridReact
                  key={'MatterSummaryViewConfigGrid'}
                  modules={AllModules}
                  gridOptions={this.gridOptions}
                  rowModelType="clientSide"
                  onGridReady={this.onGridReady}
                  rowData={this.props.views}
                  floatingFilter={false}
                  rowSelection="single"
                  suppressDragLeaveHidesColumns={false}
                  onRowSelected={this.onRowSelected}
                  animateRows={true}
                  rowHeight={40}
                  headerHeight={40}
                  columnDefs={this.viewLayout}
                  suppressNoRowsOverlay={false}
                  suppressPropertyNamesCheck={true}
                />
              </div>
            </DataGridSection>
            <ConfigContainer>
              <div className="ui divider" />
            </ConfigContainer>
            {this.getForm()}
          </Form>
        </FormStyle>
      </StyledView>
    );
  }

  private getForm() {
    return (
      <React.Fragment>
        <ConfigContainer>
          <Form.Field>
            <Checkbox
              checked={this.state.isPrivate}
              label={MatterTerminology.MyViewOnly}
              onChange={this.onCheckboxChange}
              name="isPrivate"
              style={this.state.isHideIsPrivate ? { display: 'none' } : { display: 'block' }}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              checked={this.state.isDefault}
              label={MatterTerminology.FirmDefault}
              onChange={this.onCheckboxChange}
              name="isDefault"
              style={this.state.isHideIsDefault ? { display: 'none' } : { display: 'block' }}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              checked={this.state.isTenantDefault}
              label={MatterTerminology.FirmDefault}
              onChange={this.onCheckboxChange}
              name="isTenantDefault"
              style={this.state.isHideIsTenantDefault ? { display: 'none' } : { display: 'block' }}
            />
          </Form.Field>

          {/* <Headline style={this.state.isHideRoleHeader ? { display: 'none'} : {display: 'block'}}>
            Manage Tempaltes For Roles and Security
          </Headline> */}
        </ConfigContainer>
        <ConfigContainer>
          {this.getActionButtons()}
        </ConfigContainer>
      </React.Fragment>
    );
  }

  private getActionButtons() {
    return (
      <React.Fragment>
        <StyledView>
          <div className={'modelBackdrop'}>
            {this.getSaveAsDialog()}
            {this.getRenameDialog()}
          </div>

          <Actions>
            <RvButtonFabric
              text="Update Current View"
              rvClassName="custom-button button-save"
              rvbuttonType={RvButtonType.default}
              onClick={this.handleUpdate}
              disabled={this.state.isDiableSave}
            />
            <RvButtonFabric
              text="Create New View"
              rvClassName="custom-button button-save"
              rvbuttonType={RvButtonType.default}
              onClick={this.handleSaveAs}
            />
            <RvButtonFabric
              text="Rename"
              rvClassName="custom-button button-save"
              rvbuttonType={RvButtonType.default}
              onClick={this.handleRename}
              disabled={this.state.isDiableSave}
            />
            <RvButtonFabric
              text="Delete Selected View"
              rvClassName="custom-button button-delete"
              rvbuttonType={RvButtonType.default}
              onClick={this.handleDelete}
              disabled={this.state.isDiableSave}
            />
          </Actions>
        </StyledView>
      </React.Fragment>
    );
  }

  private getSaveAsDialog() {
    return (
      <RvModal
        open={this.state.saveAsShow}
        header="View Name"
        size="mini"
        centered={false}

        content={
          <InputcontainerSaveAs>
            <RvInput
              hint={'Please specify the view name'}
              onChange={this.handleTextChange}
              value={this.state.configNameSaveAs}
              className="input-templateName"
              focus={true}
            />
          </InputcontainerSaveAs>
        }
        actionButtons={
          <ButtonContainerSaveAs>
            <RvButton
              label="Cancel"
              onClick={this.onClicCloseDialog}
            />
            <RvButton
              label="Save"
              onClick={this.onClickSaveAs}
            />
          </ButtonContainerSaveAs>
        }
      />
    );
  }

  private getRenameDialog() {
    return (
      <RvModal
        open={this.state.renameShow}
        header="Rename View Name"
        size="mini"
        centered={false}

        content={
          <InputcontainerSaveAs>
            <RvInput
              hint={'Please specify the template name'}
              onChange={this.handleTextChange}
              value={this.state.configName}
              className="input-templateName"
              focus={true}
            />
          </InputcontainerSaveAs>
        }
        actionButtons={
          <ButtonContainerSaveAs>
            <RvButton
              label="Cancel"
              onClick={this.onClicCloseDialog}
            />
            <RvButton
              label="Save"
              onClick={this.onClickRename}
            />
          </ButtonContainerSaveAs>
        }
      />
    );
  }

  private getViewLayout() {
    return ColumnConfig(this.state.isAdministrator);
  }

  private onGridReady(params: GridReadyEvent) {
    // this.columnApi = params.columnApi;

    // this.columnApi.autoSizeAllColumns();
    params.api.sizeColumnsToFit();
  }

  private onRowSelected(event: RowSelectedEvent) {
    if (event.node.isSelected()) {
      // This will send back the configId and layout. 
      if (this.props.onRowSelected) {
        this.props.onRowSelected(event.data.configId);
      }
    }
  }

  private populateData() {
    let summaryViewConfig = _.find(this.props.views, (item: SummaryViewConfigObj) => {
      return item.configId === this.state.configId;
    });

    // tslint:disable-next-line: no-console
    // console.log('summaryViewConfig', summaryViewConfig);

    let configName = '';
    let isPrivate = false;
    let isTenantDefault = false;
    let isDefault = false;
    let isDiableSave = false;

    if (summaryViewConfig !== undefined) {
      // If the template is not my view only/ personal templates, then a non-administrator user cannot update it
      if (!summaryViewConfig.isPrivate) {
        if (this.state.isAdministrator) {
          isDiableSave = false;
        } else {
          isDiableSave = true;
        }
      }

      configName = summaryViewConfig.name;
      isPrivate = summaryViewConfig.isPrivate;
      isDefault = summaryViewConfig.isDefault;
      isTenantDefault = summaryViewConfig.isTenantDefault;
    }

    // Save Tenant Default
    this.setState({
      configNameSaveAs: '',
      configName: configName,
      isPrivate: isPrivate,
      isDefault: isDefault,
      isTenantDefault: isTenantDefault,
      isDiableSave: isDiableSave
    });

    if (this.state.isAdministrator) {
      this.handleControlLogic('isPrivate', isPrivate, this.state);
    }
  }

  private onClose() {
    if (this.props.onClose !== undefined) {
      this.props.onClose(false);
    }
  }

  // tslint:disable-next-line:no-any
  private handleTextChange(event: any) {
    this.setState({
      configNameSaveAs: event.currentTarget.value,
      configName: event.currentTarget.value,
    });
  }

  private onCheckboxChange(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) {
    let configState = this.state;
    configState[data.name!] = data.checked;

    configState = this.handleControlLogic(data.name, data.checked, configState);

    this.setState({
      ...configState
    });
  }

  private handleControlLogic(name: string | undefined, checked: boolean | undefined, configState: SummaryViewConfigState) {

    let isChecked = checked === undefined ? false : checked;

    if (name === 'isPrivate') {
      if (isChecked) {
        configState.isPrivate = true;
        configState.isTenantDefault = false;
        configState.isDefault = false;
        configState.isHideIsTenantDefault = true;
        configState.isHideIsDefault = false;
      } else {
        configState.isPrivate = false;
        configState.isTenantDefault = false;
        configState.isDefault = false;
        configState.isHideIsTenantDefault = false;
        configState.isHideIsDefault = true;
      }
    }

    if (!this.state.isAdministrator) {
      configState.isTenantDefault = false;
      configState.isHideIsTenantDefault = true;

      // if (name === 'isDefault') {

      // }

    }

    return configState;
  }

  // Update existing Record
  private handleUpdate() {
    this.setState({
      updateShow: true
    });
  }

  // Always create a new record
  private handleSaveAs() {
    this.setState({
      saveAsShow: true
    });
  }

  // Rename the existing record
  private handleRename() {
    this.setState({
      renameShow: true
    });
  }

  // Delete the selected config
  private handleDelete() {
    this.setState({
      deleteShow: true
    });
  }

  // Validate the data before saving
  private validateInput(isUpdate: boolean): Boolean {
    const templateNames = this.props.views;

    let isValid: Boolean = true;

    if (isUpdate && this.state.configId === 0) {
      isValid = false;
      showNotification('Incorrect input', 'Please select a template to Rename.', 'error');
    }

    if (this.state.configName.length === 0) {
      isValid = false;
      showNotification('Incorrect input', 'Please specify the template name to continue.', 'error');
    }

    // Check for already existing Template Names.
    if (!isUpdate) {
      var templateName = _.find(templateNames, (item: SummaryViewConfigObj) => {
        return item.name === this.state.configName;
      });

      if (templateName !== undefined) {
        isValid = false;
        showNotification('Incorrect input', 'Please specify a unique template name to continue.', 'error');
      }
    }

    return isValid;
  }

  private onClickSaveAs(event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) {
    const isValid = this.validateInput(false);

    if (isValid) {

      // Saving a new Template
      this.SaveColumnConfig(0, false);
      this.setState({
        saveAsShow: false,
        configNameSaveAs: ''
      });
    }
  }

  private onClickRename(event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) {
    const isValid = this.validateInput(true);

    if (isValid) {

      // Saving a new Template
      this.SaveColumnConfig(this.state.configId, false);

      this.setState({
        renameShow: false,
        configNameSaveAs: ''
      });
    }
  }

  private onClicCloseDialog(event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) {
    this.setState({
      saveAsShow: false,
      renameShow: false,
      configNameSaveAs: ''
    });
  }

  private SaveColumnConfig(configId: number, isDelete: boolean) {
    // If the user is not an administrator, then it is always a private template
    let isPrivate = !this.state.isAdministrator ? true : this.state.isPrivate;
    let isTenantDefault = !this.state.isAdministrator ? false : this.state.isTenantDefault;

    if (this.props.viewConfig !== undefined) {
      SaveSummaryViewConfiguration(
        configId,
        this.state.configName,
        false,
        isPrivate,
        this.state.isDefault,
        isTenantDefault,
        this.props.configType,
        this.props.viewConfig,
        isDelete,
        this.props.isNewMatterSummary,
        () => {
          // Success 
          showNotification(null, 'Successfully submitted', 'info');
        },
        (reason) => {
          showNotification('Failed to Save Template', reason, 'error');
        }
      );
    }
  }

  private handleUpdateAgreed(isUpdate: boolean) {
    const vm = this;

    if (isUpdate) {
      const isValid = vm.validateInput(true);

      if (isValid) {
        vm.SaveColumnConfig(this.state.configId, false);
      }
    }

    this.setState({
      updateShow: false
    });
  }

  private handleDeleteAgreed(isDelete: boolean) {
    if (isDelete) {
      const isValid = this.validateInput(true);

      if (isValid) {
        this.SaveColumnConfig(this.state.configId, true);
      }
    }

    this.setState({
      deleteShow: false
    });
  }
}