import gql from 'graphql-tag';
import { client } from '..';

// export interface SummaryViewSelectedRoles {
//     roleId?: string | null;
//     isDefault: boolean;
// }

export interface Roles {
    key: string;
    value: string;
    text: string;
}

// export function savePersonalMatterSummaryConfiguration(configName: string,
//                                                        config: string,  
//                                                         // tslint:disable-next-line:no-any
//                                                        onSuccess: ( ) => void,
//                                                         // tslint:disable-next-line:no-any
//                                                        onError: (reason: any) => void): void {
//     client.mutate({
//                 mutation: PersonalMatterSummaryCfgMutation,
//                 variables: {
//                                 columnConfigJson: config,
//                                 name: configName
//                             }
//     })
//         // tslint:disable-next-line:no-any
//         .then((results: { data: any; }) => { onSuccess( ); })
//         // tslint:disable-next-line:no-any
//         .catch((reason: any) => { onError(reason); }
//     );
// }

export function SaveSummaryViewConfiguration(configId: number, 
                                             name: string,
                                             isSecurity: boolean,
                                             isPrivate: boolean,
                                             isDefault: boolean,
                                             isTenantDefault: boolean,
                                             configType: number,
                                             columnConfigJson: string, 
                                             isDelete: boolean,
                                             isNewMatterSummary: boolean,
                                             onSuccess: ( ) => void,
                                             // tslint:disable-next-line:no-any
                                             onError: (reason: any) => void): void {
    client.mutate({
                mutation: SummaryViewConfigurationMutation,
                variables: {
                    configId: configId,
                    name: name,
                    isSecurity: isSecurity,
                    isPrivate: isPrivate,
                    isDefault: isDefault,
                    isTenantDefault: isTenantDefault,
                    configType: configType,
                    columnConfigJson: columnConfigJson,
                    isDelete: isDelete,
                    isNewMatterSummary: isNewMatterSummary
                }
    })
        // tslint:disable-next-line:no-any
        .then((results: { data: any; }) => { 
            if (results.data) {
                if (results.data.error === null || results.data.error === undefined) {
                    onSuccess(); 
                } else {
                    onError(results.data.error); 
                }
            }            
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => { onError(reason); }
    );
}

const SummaryViewConfigurationMutation = 
gql`mutation summaryViewConfigurationMutation (
        $configId: Int, 
        $name: String,
        $isSecurity: Boolean,
        $isPrivate: Boolean,
        $isDefault: Boolean,
        $isTenantDefault: Boolean,  
        $configType: Int,
        $columnConfigJson: String,
        $isDelete: Boolean,
        $isNewMatterSummary: Boolean
  ) {
    customizeColumnConfig(
        configId: $configId,
        name: $name,
        isSecurity: $isSecurity,
        isPrivate: $isPrivate,
        isDefault: $isDefault,
        isTenantDefault: $isTenantDefault,
        configType: $configType,
        columnConfigJson: $columnConfigJson,
        isDelete: $isDelete,
        isNewMatterSummary: $isNewMatterSummary
  ) 
}`;

export const SummaryViewConfigNotification = gql`subscription summaryViewConfigNotification {
    summaryViewConfigNotification{
      id
      status
      message
      description
    }
}`;